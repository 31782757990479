<script lang="ts" setup>
import type { ProductListItem } from "~/models/products";

defineProps<{ product: ProductListItem; locale: string }>();
defineEmits(["select"]);

const { meta } = useRoute();
const basePath = computed(() => meta.catalogSearchType === "promotion" ? "/promotion" : "");
</script>

<template>
  <NuxtLinkLocale :to="`${basePath}${product.clusterUrl}`" @click.prevent="$emit('select', product)">
    <div class="product-card-wrapper">
      <div class="product-card">
        <ProductImage
          :image="product.image"
          :color="product.color"
          :is-available="product.isAvailable"
          :is-new="product.isNew"
          :is-out-of-stock="product.isOutOfStock"
          :is-promo="product.isPromo"
          class="size-full object-contain"
          sizes="108px md:300px"
        />

        <div class="p-3">
          <div class="product-card__name">
            <h4>{{ product.clusterName }}</h4>
          </div>

          <div class="product-card__info">
            <div class="text-primary">
              {{ formatProductSubTitle(product.year, product.country, product.region) }}
            </div>

            <div>
              <span>{{ product.volume }}</span>
            </div>
          </div>

          <div class="product-card__price">
            <div class="flex items-end gap-x-2 font-bold">
              <span v-if="product.isPromo">{{ formatCurrency(product.pricePromo) }}</span>
              <span :class="{ 'line-through': product.isPromo, 'text-gray-300': product.isPromo }">
                {{ formatCurrency(product.priceUser) }}
              </span>
            </div>
          </div>

          <div class="product-card__discount-text" v-html="formatDiscounts(product.discounts)" />
        </div>
      </div>
    </div>
  </NuxtLinkLocale>
</template>

<style scoped>
.product-card-wrapper {
  container: component card / inline-size;
}

.product-card {
  @apply relative w-full border-4 bg-white border-gray-50 hover:border-gray-100;
}

.product-card__name {
  @apply line-clamp-2 h-[55px];
}

.product-card__info {
  @apply flex flex-wrap justify-between min-h-[30px];
}

.product-card__price {
  @apply font-bold h-[30px];
}

.product-card__discount-text {
  @apply min-h-[20px] text-sm;
}

@container card (min-width: 500px) {
  .product-card {
    display: grid;
    grid-template:
      "image name" auto
      "image info" 1fr
      "image price" 1fr
      "image discount-text" 1fr
      / minmax(150px, 200px) 1fr;

    & .product-image-wrapper {
      @apply h-[200px];
      grid-area: image;
    }

    & .product-card__name {
      grid-area: name;
    }

    & .product-card__info {
      grid-area: info;
    }

    & .product-card__price {
      grid-area: price;
    }

    & .product-card__discount-text {
      grid-area: discount-text;
    }
  }
}
</style>
